import * as Constants from "./constant";
import * as cryptojs from 'crypto-js';
import { isValid } from "iban";
import * as turf from '@turf/turf';

export const getToken = () => {
  return localStorage.getItem(Constants.AUTH_TOKEN);
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(Constants.AUTH_TOKEN, token);
  }
};

export const removeToken = () => {
  localStorage.removeItem(Constants.AUTH_TOKEN);
};


export const diff_years = (dt2, dt1) => {
  // Calculate the difference in milliseconds between the two dates
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  // Convert the difference from milliseconds to days
  diff /= (60 * 60 * 24);
  // Calculate the approximate number of years by dividing the difference in days by the average number of days in a year (365.25)
  return Math.abs(Math.round(diff / 365.25));
}


export const isLoggedIn = () => {
  return getToken() != null;
};



export const getPermisosConducir = () => {
  return JSON.parse(localStorage.getItem(Constants.PERMISOS_CONDUCIR));
};
export const setPermisosConducir = (data) => {
  //console.log(JSON.stringify(data))
  if (data) {
    localStorage.setItem(Constants.PERMISOS_CONDUCIR, JSON.stringify(data));
  }
};
export const removePermisosConducir = () => {
  localStorage.removeItem(Constants.PERMISOS_CONDUCIR);
};

export const getIdiomas = () => {
  return JSON.parse(localStorage.getItem(Constants.IDIOMAS));
};
export const setIdiomas = (data) => {
  if (data) {
    localStorage.setItem(Constants.IDIOMAS, JSON.stringify(data));
  }
};
export const removeIdiomas = () => {
  localStorage.removeItem(Constants.IDIOMAS);
};

export const getNivelIdiomas = () => {
  return JSON.parse(localStorage.getItem(Constants.NIVEL_IDIOMAS));
};
export const setNivelIdiomas = (data) => {
  if (data) {
    localStorage.setItem(Constants.NIVEL_IDIOMAS, JSON.stringify(data));
  }
};
export const removeNivelIdiomas = () => {
  localStorage.removeItem(Constants.NIVEL_IDIOMAS);
};

export const getJornadas = () => {
  return JSON.parse(localStorage.getItem(Constants.JORNADAS));
};
export const setJornadas = (data) => {
  //console.log(JSON.stringify(data));
  if (data) {
    localStorage.setItem(Constants.JORNADAS, JSON.stringify(data));
  }
};
export const removeJornadas = () => {
  localStorage.removeItem(Constants.JORNADAS);
};

export const getTipoEstudios = () => {
  return JSON.parse(localStorage.getItem(Constants.TIPO_ESTUDIOS));
};
export const setTipoEstudios = (data) => {
  if (data) {
    localStorage.setItem(Constants.TIPO_ESTUDIOS, JSON.stringify(data));
  }
};
export const removeTipoEstudios = () => {
  localStorage.removeItem(Constants.TIPO_ESTUDIOS);
};

export const getTiposContrato = () => {
  return JSON.parse(localStorage.getItem(Constants.TIPO_CONTRATO));
};
export const setTiposContrato = (data) => {
  if (data) {
    localStorage.setItem(Constants.TIPO_CONTRATO, JSON.stringify(data));
  }
};
export const removeTiposContrato = () => {
  localStorage.removeItem(Constants.TIPO_CONTRATO);
};

export const getSectores = () => {
  return JSON.parse(localStorage.getItem(Constants.SECTORES));
};
export const setSectores = (data) => {
  //console.log(JSON.stringify(data));
  if (data) {
    localStorage.setItem(Constants.SECTORES, JSON.stringify(data));
  }
};
export const removeSectores = () => {
  localStorage.removeItem(Constants.SECTORES);
};
export const fetchSectores = async () => {
  let sectores = [];
  try {
    const response = await fetch(`${Constants.API}/sectores`, {
    });
    const responseData = await response.json();

    var data = [];
    for (var n in responseData.data) {
      data.push({ id: responseData.data[n].id, nombre: responseData.data[n].attributes.Nombre });
    }
    console.log(data);
    sectores = data;
  } catch (error) {
    console.error(error);
    console.error("Error recuperando IDs de DB");
  } finally {
    return sectores;
  }
};

export const getTipo = () => {
  return JSON.parse(localStorage.getItem(Constants.TIPO));
};
export const setTipo = (data) => {
  //console.log(JSON.stringify(data));
  if (data) {
    localStorage.setItem(Constants.TIPO, JSON.stringify(data));
  }
};
export const removeTipo = () => {
  localStorage.removeItem(Constants.TIPO);
};

export const getMiCandidatoId = () => {
  return JSON.parse(localStorage.getItem(Constants.MI_CANDIDATO_ID));
};
export const setMiCandidatoId = (data) => {
  //console.log(`setMiCandidatoId:${JSON.stringify(data)}`);
  if (data) {
    localStorage.setItem(Constants.MI_CANDIDATO_ID, JSON.stringify(data));
  }
};
export const removeMiCandidatoId = () => {
  localStorage.removeItem(Constants.MI_CANDIDATO_ID);
};

export const getMiEmpresaId = () => {
  return JSON.parse(localStorage.getItem(Constants.MI_EMPRESA_ID));
};
export const setMiEmpresaId = (data) => {
  //console.log(JSON.stringify(data));
  if (data) {
    localStorage.setItem(Constants.MI_EMPRESA_ID, JSON.stringify(data));
  }
};
export const removeMiEmpresaId = () => {
  localStorage.removeItem(Constants.MI_EMPRESA_ID);
};

export const getMisSedesId = () => {
  return JSON.parse(localStorage.getItem(Constants.MIS_SEDES_ID));
};
export const setMisSedesId = (data) => {
  //console.log(JSON.stringify(data));
  if (data) {
    localStorage.setItem(Constants.MIS_SEDES_ID, JSON.stringify(data));
  }
};
export const removeMisSedesId = () => {
  localStorage.removeItem(Constants.MIS_SEDES_ID);
};

export const getMiUserId = () => {
  return JSON.parse(localStorage.getItem(Constants.MI_USER_ID));
};
export const setMiUserId = (data) => {
  //console.log(JSON.stringify(data));
  if (data) {
    localStorage.setItem(Constants.MI_USER_ID, JSON.stringify(data));
  }
};
export const removeMiUserId = () => {
  localStorage.removeItem(Constants.MI_USER_ID);
};

export const getTrabajos = () => {
  return JSON.parse(localStorage.getItem(Constants.TRABAJOS));
};
export const setTrabajos = (data) => {
  if (data) {
    localStorage.setItem(Constants.TRABAJOS, JSON.stringify(data));
  }
};
export const removeTrabajos = () => {
  localStorage.removeItem(Constants.TRABAJOS);
};


export const logout = () => {
  removeToken();
  removePermisosConducir();
  removeIdiomas();
  removeNivelIdiomas();
  removeJornadas();
  removeTipoEstudios();
  removeSectores();
  removeTipo();
  removeMiCandidatoId();
  removeMiEmpresaId();
  removeMisSedesId();
  removeMiUserId();
  removeTiposContrato();
  removeTrabajos();
  removeMiPlan();
  removeMiPlanNombre();
  removeRedsysData();
  localStorage.clear();
};


export const hideShow = (hideId, showId) => {
  //console.log('toggling:' + hideId + ' ' + showId);
  var hide = document.getElementById(hideId);
  if (hide != null)
    hide.classList.toggle('hidden')
  var show = document.getElementById(showId)
  if (show != null)
    show.classList.toggle('hidden')
}

export const getAPIErrorMessage = (data) => {
  let error = (data != null && data.error != null ? data.error.name + '. ' + data.error.message : 'Undefined')
  if (data.error.details != null && data.error.details.errors != null && data.error.details.errors.length > 0) {
    error = '';
    let aux = null;
    for (let i in data.error.details.errors) {
      aux = data.error.details.errors[i];
      error += aux.name + '. ' + aux.message + ': ' + aux.path[0] + '. ';
    }
  }
  return error;
}

export const getDataErrorMessage = (data) => {
  let error = null;
  if (data.toLowerCase().indexOf('email') != -1 && data.toLowerCase().indexOf('already taken') != -1) {
    error = 'Error: El correo electrónico introducido ya está registrado';
  } else if (data.toLowerCase().indexOf('unique') != -1 && data.toLowerCase().indexOf('nif') != -1) {
    error = 'Error: El NIF introducido ya está registrado';
  } else
    error = "Algo ha ido mal. Por favor, vuelve a probar en unos momentos.";
  return error;
}

export const callAPI = (url, method, headers, body, successFunction, errorFunction) => {
  var fetchObj = { method: method != null ? method : 'GET' };
  if (headers != null) fetchObj.headers = headers;
  if (body != null) fetchObj.body = body;

  return fetch(url, fetchObj)
    .then(data => data.json())
    .then(data => {
      //console.log(data);
      if (data != null && data.error == null) {
        successFunction(data);
      } else {
        //errorFunction(data != null && data.error != null ? data.error.name + '. ' + data.error.message : 'Undefined');
        errorFunction(getAPIErrorMessage(data));
      }
    })
    .catch(data => {
      //console.log(data);
      //errorFunction(data != null && data.error != null ? data.error.name + '. ' + data.error.message : 'Undefined');
      errorFunction(getAPIErrorMessage(data));
    })
    ;
}

/*export const geocodeByPostalcode = (postalCode) => {
  callAPI(Constants.NOMINATIM_QUERY+postalCode,'GET',null,null,console.log(data), consol)
}*/

export const dedupArray = (data) => {
  return data.filter((v, i, a) => a.findIndex(v2 => (JSON.stringify(v2) === JSON.stringify(v))) === i);
}

export const dedupIdsArray = (data) => {
  let aux = [];
  for (let n in data) {
    aux.push(parseInt(data[n]));
  }
  return dedupArray(aux);
}

export const removeIndexFromArray = (data, index) => {
  return data.slice(0, index).concat(data.slice(index + 1));
}

export const hideShowOverlay = (id, id2) => {
  //console.log(`${id} ${id2}`)
  var hideShow = document.getElementById(id);
  if (hideShow != null)
    hideShow.classList.toggle('hidden')
  if (id2 != null) {
    var hideShow2 = document.getElementById(id2);
    if (hideShow2 != null)
      hideShow2.classList.toggle('hidden')
  }
}

export const hideAllOverlays = () => {
  var overlays = document.getElementsByName(Constants.SIDEBAR_OVERLAY);
  for (var n in overlays) {
    if (overlays[n] != null && overlays[n].classList != null && overlays[n].classList.contains('hidden') != -1)
      overlays[n].classList.add('hidden');
  }
}

export const isMobileDevice = () => {
  const mobileDevices = ['Android', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone'];
  return mobileDevices.some((device) => navigator.userAgent.includes(device));
};

export const getCandidato = async (id) => {
  var result = null;
  if (id == null)
    return result;
  try {
    const response = await fetch(`${Constants.API}/candidatos/${id}?populate=*`, {
      headers: { Authorization: `${Constants.BEARER} ${getToken()}` },
    });
    const responseData = await response.json();
    result = responseData.data.attributes;
    result.id = id;
    return result;
  } catch (error) {
    console.error(error);
    console.error("Error recuperando IDs de DB");
  } finally {
    return result;
  }
};

export const getSede = async (id) => {
  var result = null;
  try {
    const response = await fetch(`${Constants.API}/sedes/${id}?populate=*`, {
      headers: { Authorization: `${Constants.BEARER} ${getToken()}` },
    });
    const responseData = await response.json();

    result = responseData.data;
  } catch (error) {
    console.error(error);
    console.error("Error recuperando IDs de DB");
  } finally {
    return result;
  }
};

export const getEmpresa = async (id) => {
  var result = null;
  try {
    const response = await fetch(`${Constants.API}/empresas/${id}?populate=*`, {
      headers: { Authorization: `${Constants.BEARER} ${getToken()}` },
    });
    const responseData = await response.json();

    result = responseData.data;
  } catch (error) {
    console.error(error);
    console.error("Error recuperando IDs de DB");
  } finally {
    return result;
  }
};

export function decode64(data) {
  var words = cryptojs.enc.Base64.parse(data);
  var textString = cryptojs.enc.Utf8.stringify(words);

  return textString;
}

export function encode64(data) {
  var wordArray = cryptojs.enc.Utf8.parse(data);
  var base64 = wordArray.toString(cryptojs.enc.Base64);

  return base64;
}

// Utility function to calculate distance between two points (Haversine formula)
export const calculateDistance = (coord1, coord2) => {
  const [lat1, lon1] = coord1;
  const [lat2, lon2] = coord2;
  const R = 6371; // Radius of Earth in km
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
};

// Función para cambiar el formato [lat, lon] a [lon, lat]
function convertirLatLngALngLat(coordenadas) {
  // Si es un solo punto [lat, lon]
  if (coordenadas.length === 2) {
    return [parseFloat(coordenadas[1]), parseFloat(coordenadas[0])];  // Invertir el par
  }
  // Si es un array de puntos [[lat, lon], [lat, lon], ...]
  return coordenadas.map(coord => [parseFloat(coord[1]), parseFloat(coord[0])]); // Invertir cada punto
}

// Función para verificar si un punto (lat, long) está dentro de un polígono
export const puntoEnPoligono = (puntoCoords, poligonoCoords) => {
  // El polígono debe ser cerrado, el último punto debe ser el mismo que el primero
  poligonoCoords.push(poligonoCoords[0])
  // Crear un punto (en formato [longitud, latitud])
  const punto = turf.point(convertirLatLngALngLat(puntoCoords));
  // Crear un polígono con coordenadas en formato [longitud, latitud]
  // Debe ser en forma de anillo, por lo que hay que envolver las coordenadas con []
  const poligono = turf.polygon([convertirLatLngALngLat(poligonoCoords)]);
  // Verificar si el punto está dentro del polígono
  let dentro = turf.booleanPointInPolygon(punto, poligono);

  return dentro;
}

export const _filterResultsByProximity = (center, radius, results) => {
  var aux = [];
  if (results != null) {

    if (getTipo() == Constants.CANDIDATO) {
      // Buscar por distancia punto a punto
      for (var n in results) {
        results[n].position = [results[n].attributes.Lat, results[n].attributes.Lon]
      }
      aux = results.filter((result) => {
        const distance = calculateDistance(center.split(','), result.position);
        return distance <= radius;
      });
    } else {
      // Buscar por punto incluido en zona
      let poligonoZona = null;
      let puntoDentroDeZona = null;
      for (var n in results) {
        let addResult = false
        for (var m in results[n].attributes.zonas.data) {
          poligonoZona = JSON.parse(results[n].attributes.zonas.data[m].attributes.BoundingBox)
          puntoDentroDeZona = puntoEnPoligono(center.split(','), poligonoZona);
          console.log(puntoDentroDeZona)
          if (puntoDentroDeZona) {
            addResult = true;
          }
        }
        if (addResult) {
          aux.push(results[n]);
        }
      }
    }
  }

  return aux;
};

export const getFavoritosCandidato = async (id) => {
  var result = null;
  try {
    const response = await fetch(`${Constants.API}/candidatos/${id != null ? id : getMiCandidatoId()}?populate[0]=favoritos`, {
      headers: { Authorization: `${Constants.BEARER} ${getToken()}` },
    });
    const responseData = await response.json();

    if (responseData.data.attributes.favoritos != null) {
      result = [];
      for (var n in responseData.data.attributes.favoritos.data) {
        if (!responseData.data.attributes.favoritos.data[n].attributes.Inactivo)
          result.push(await getSede(responseData.data.attributes.favoritos.data[n].id));
      }
    }
    return result;
  } catch (error) {
    console.error(error);
    console.error("Error recuperando IDs de DB");
  } finally {
    return result;
  }
};

export const getFavoritosEmpresa = async (id) => {
  var result = null;
  try {
    const response = await fetch(`${Constants.API}/empresas/${getMiEmpresaId()}?populate[0]=favoritos`, {
      headers: { Authorization: `${Constants.BEARER} ${getToken()}` },
    });
    const responseData = await response.json();

    if (responseData.data.attributes.favoritos != null) {
      result = [];
      for (var n in responseData.data.attributes.favoritos.data) {
        if (!responseData.data.attributes.favoritos.data[n].attributes.Inactivo)
          result.push(await getCandidato(responseData.data.attributes.favoritos.data[n].id));
      }
    }

    return result;
  } catch (error) {
    console.error(error);
  } finally {
    return result;
  }
};

export const getFavoritosCandidatoIds = async (id) => {
  var aux = await getFavoritosCandidato(id);
  var favoritos = [];
  for (var n in aux) {
    favoritos.push(aux[n].id);
  }
  return favoritos;
}

export const getFavoritosEmpresaIds = async () => {
  var aux = await getFavoritosEmpresa();
  var favoritos = [];
  for (var n in aux) {
    favoritos.push(aux[n].id);
  }
  return favoritos;
}

export const getFavoritos = async (id) => {
  var tipo = getTipo();
  var favoritos = [];
  if (getMiCandidatoId() != null || getMiEmpresaId() != null) {
    if (tipo == Constants.CANDIDATO) {
      favoritos = await getFavoritosCandidato();
    } else {
      favoritos = await getFavoritosEmpresa(id);
    }
  }
  return favoritos;
}

export const getFavoritosIds = async () => {
  var tipo = getTipo();
  var favoritos = null;
  if (getMiCandidatoId() != null || getMiEmpresaId() != null) {
    if (tipo == Constants.CANDIDATO) {
      favoritos = await getFavoritosCandidatoIds();
    } else {
      favoritos = await getFavoritosEmpresaIds();
    }
  }
  return favoritos;
}

export const isFav = async (id) => {
  var isFav = false;
  var favoritos = await getFavoritosIds();
  isFav = favoritos != null && favoritos.indexOf(parseInt(id)) > -1;
  return isFav;
}

export const imFav = async (id) => {
  var tipo = getTipo();
  var imFav = false;
  if (tipo == Constants.EMPRESA) {
    var favoritos = await getFavoritosCandidatoIds(id);
    const aux = getMisSedesId().filter(value => favoritos.includes(value));
    imFav = aux.length > 0;
  }
  return imFav;
}

export const toggleFav = async (id) => {
  var favIds = await getFavoritosIds();
  var newFavIds = favIds != null ? favIds : [];
  id = parseInt(id);
  if (favIds.indexOf(id) > -1) {
    newFavIds = favIds.filter(e => e !== id);
  } else {
    newFavIds.push(id);
  }
  newFavIds = dedupArray(newFavIds);
  try {
    var url = getTipo() == Constants.CANDIDATO ? `${Constants.API}/candidatos/${getMiCandidatoId()}` : `${Constants.API}/empresas/${getMiEmpresaId()}`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "data": { "favoritos": newFavIds } }),
    });
    const responseData = await response.json();
  } catch (error) {
    console.error(error);
  }
}

export const getBloqueados = async (id) => {
  var tipo = getTipo();
  var favoritos = [];
  if (getMiCandidatoId() != null) {
    if (tipo == Constants.CANDIDATO) {
      favoritos = await getBloqueadosCandidato();
    }
  }
  return favoritos;
}

export const getBloqueadosCandidato = async (id) => {
  var result = null;
  try {
    const response = await fetch(`${Constants.API}/candidatos/${id != null ? id : getMiCandidatoId()}?populate[0]=bloqueados`, {
      headers: { Authorization: `${Constants.BEARER} ${getToken()}` },
    });
    const responseData = await response.json();

    if (responseData.data.attributes.bloqueados != null) {
      result = [];
      for (var n in responseData.data.attributes.bloqueados.data) {
        if (!responseData.data.attributes.bloqueados.data[n].attributes.Inactivo)
          result.push(await getSede(responseData.data.attributes.bloqueados.data[n].id));
      }
    }
    return result;
  } catch (error) {
    console.error(error);
    console.error("Error recuperando IDs de DB");
  } finally {
    return result;
  }
};

export const getBloqueadosCandidatoIds = async (id) => {
  var aux = await getBloqueadosCandidato(id);
  var bloqueados = [];
  for (var n in aux) {
    bloqueados.push(aux[n].id);
  }
  return bloqueados;
}

export const getBloqueadosIds = async () => {
  var tipo = getTipo();
  var bloqueados = null;
  if (getMiCandidatoId() != null) {
    if (tipo == Constants.CANDIDATO) {
      bloqueados = await getBloqueadosCandidatoIds();
    }
  }
  return bloqueados;
}

export const isBloq = async (id) => {
  var isBloq = false;
  var bloqueados = await getBloqueadosIds();
  isBloq = bloqueados != null && bloqueados.indexOf(parseInt(id)) > -1;
  return isBloq;
}

export const imBloq = async (id) => {
  var tipo = getTipo();
  var imBloq = false;
  if (tipo == Constants.EMPRESA) {
    var bloqueados = await getBloqueadosCandidatoIds(id);
    const aux = getMisSedesId().filter(value => bloqueados.includes(value));
    imBloq = aux.length > 0;
  }
  return imBloq;
}

export const getCandidatosBloqMe = async () => {
  let result = { status: 0 };

  if (getTipo() == Constants.EMPRESA) {
    try {
      let misSedesId = getMisSedesId();
      if (misSedesId != null && misSedesId.length > 0) {
        var url = `${Constants.API}/candidatos?populate=bloqueados&filters[Inactivo]=0`;
        for (let n in misSedesId) {
          url += `&filters[bloqueados][id][$in][${n}]=${misSedesId[n]}`;
        }
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `${Constants.BEARER} ${getToken()}`,
          },
        });

        let responseData = await response.json();
        if (responseData != null && responseData.error != null) {
          result.status = 1;
          result.error = getErrorMsg(responseData);
        } else if (responseData.data.length > 0) {
          result.data = [];
          for (var n in responseData.data) {
            result.data.push(responseData.data[n].id);
          }
        }
      } else {
        result.data = [];
      }
    } catch (error) {
      //console.error(error);
      result.status = 1;
      result.error = error;
    }
  } else {
    result.status = 1;
    result.error = 'Error!'
  }
  return result;
}

export const toggleBloq = async (id) => {
  var bloqIds = await getBloqueadosIds();
  var newBloqIds = bloqIds != null ? bloqIds : [];
  if (bloqIds.indexOf(id) > -1) {
    newBloqIds = bloqIds.filter(e => e !== id);
  } else {
    newBloqIds.push(id);
  }
  newBloqIds = dedupArray(newBloqIds)
  if (getTipo() == Constants.CANDIDATO) {
    try {
      var url = `${Constants.API}/candidatos/${getMiCandidatoId()}`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `${Constants.BEARER} ${getToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "data": { "bloqueados": newBloqIds } }),
      });
      const responseData = await response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export const removeCandidatosBloqMe = (candidatos, candidatosBloqMe) => {
  let result = candidatos;
  if (candidatosBloqMe != null) {
    result = result.filter(item => !candidatosBloqMe.includes(item.id));
    result = result.filter(item => !candidatosBloqMe.includes(item.attributes.id));
  }
  return result;
}

export const findAndRemoveCandidatosBloqMe = async (candidatos) => {
  let result = candidatos;
  let candidatosBloqMe = await getCandidatosBloqMe();
  if (candidatosBloqMe != null && candidatosBloqMe.status == 0 && candidatosBloqMe.data != null && candidatosBloqMe.data.length > 0) {
    candidatosBloqMe = candidatosBloqMe.data;
    result = result.filter(item => !candidatosBloqMe.includes(item.id));
    result = result.filter(item => !candidatosBloqMe.includes(item.attributes.id));
  }
  return result;
}

export const getContactadosEmpresa = async () => {
  try {
    var result = [];
    var url = `${Constants.API}/contactados/?populate=*&filters[empresa][id]=${getMiEmpresaId()}`
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
      },
    });
    const responseData = await response.json();
    if (responseData.data.length > 0) {
      for (var n in responseData.data) {
        if (responseData.data[n].attributes.candidato != null && responseData.data[n].attributes.candidato.data != null)
          result.push(await getCandidato(responseData.data[n].attributes.candidato.data.id));
      }
    }
  } catch (error) {
    console.error(error);
  }

  return result;
}

export const getContactadosCandidato = async () => {
  try {
    var result = [];
    var url = `${Constants.API}/contactados/?populate=*&filters[candidato][id]=${getMiCandidatoId()}`
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
      },
    });
    let responseData = await response.json();
    if (responseData.data.length > 0) {
      for (var n in responseData.data) {
        if (responseData.data[n].attributes.empresa != null && responseData.data[n].attributes.empresa.data != null) {
          let sedes = await getSedesByEmpresa(responseData.data[n].attributes.empresa.data.id);
          if (sedes.status == 0) {
            /*for (var m in sedes.data) {
              result.push(await getSede(sedes.data[m]));
            }*/
            if (sedes.data.length > 0) {
              result.push(await getSede(sedes.data[0]));
            }
          }
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
  return result;
}

export const getContactados = async () => {
  var aux = null;
  if (getTipo() == Constants.EMPRESA) {
    aux = await getContactadosEmpresa();
  } else {
    aux = await getContactadosCandidato();
  }
  return aux;
}

export const makeContact = async (id) => {
  var result = null;
  try {

    var url = `${Constants.API}/contactados/?populate=*&filters[0][candidato]=${id}&filters[1][empresa]=${getMiEmpresaId()}`
    var response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
      },
    });
    var responseData = await response.json();
    if (responseData.data.length == 0) {
      url = `${Constants.API}/contactados/`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `${Constants.BEARER} ${getToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "data": { "empresa": getMiEmpresaId(), "candidato": id } }),
      });
    } else {
      url = `${Constants.API}/contactados/${responseData.data[0].id}`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `${Constants.BEARER} ${getToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "data": { "updatedAt": new Date() } }),
      });
    }
  } catch (error) {
    console.error(error);
  } finally {
    return result;
  }
}

export const getInfoFiscalCandidato = async () => {
  var result = null;
  try {
    var url = `${Constants.API}/info-fiscals/?populate=*&filters[user][id]=${getMiUserId()}`
    var response = await fetch(url, {
      method: 'GET',
      headers: { Authorization: `${Constants.BEARER} ${getToken()}` },
    });
    var responseData = await response.json();
    if (responseData.data != null && responseData.data.length > 0)
      result = responseData.data[0];
    return result;
  } catch (error) {
    console.error(error);
  } finally {
    return result;
  }
}

export const updateInfoFiscalCandidato = async (data) => {
  var infoFiscalActual = await getInfoFiscalCandidato();
  var responseData = null;
  if (infoFiscalActual != null) {
    try {
      const response = await fetch(`${Constants.API}/info-fiscals/${infoFiscalActual.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `${Constants.BEARER} ${getToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "data": data }),
      });
      responseData = await response.json();
    } catch (error) {
      console.error(error);
    }
  } else {
    try {
      const response = await fetch(`${Constants.API}/info-fiscals/`, {
        method: 'POST',
        headers: {
          Authorization: `${Constants.BEARER} ${getToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "data": data }),
      });
      responseData = await response.json();
      //console.log(responseData);
    } catch (error) {
      console.error(error);
    }
  }
  //console.log(responseData);
}

export const uploadFile = async (fileField) => {
  var url = null;
  try {
    if (document.getElementById(fileField) != null && document.getElementById(fileField).files != null && document.getElementById(fileField).files.length == 1) {
      let file = new FormData();
      file.append("files", document.getElementById(fileField).files[0]);
      var response = await fetch(`${Constants.API}/upload`, {
        method: 'POST',
        headers: {
          Authorization: `${Constants.BEARER} ${getToken()}`,
        },
        body: file,
      });

      var responseData = await response.json();
      url = responseData[0].url;
      console.log(responseData);
    } else {
      //console.log(`No hay ningún fichero para subir en ${fileField}`);
    }
  } catch (e) {
    console.log(e)
  }
  return url;

}

export const updateFormFieldValue = (fieldName, fieldValue) => {
  if (document.getElementById(fieldName) != null)
    document.getElementById(fieldName).value = fieldValue;
}

export const validarSS = (nss) => {
  // Eliminar cualquier espacio en blanco en el número de seguridad social
  nss = nss.replace(/\s+/g, '');

  // Comprobar que tiene exactamente 12 dígitos
  if (!/^\d{12}$/.test(nss)) {
    return false;
  }

  // Dividir el número en sus partes
  let provincia = nss.slice(0, 2);
  let numero = nss.slice(2, 10);
  let control = nss.slice(10, 12);

  // Convertir las partes a números enteros
  let numeroCompleto = parseInt(provincia + numero, 10);
  let digitosControl = parseInt(control, 10);

  // Calcular los dos últimos dígitos de control
  let calculado = numeroCompleto % 97;

  // Verificar que los dígitos de control coinciden
  return calculado === digitosControl;
}


export const validarIBAN = (iban) => {
  return isValid(iban);
}


export const validarNIF = (documento) => {
  documento = documento.toUpperCase();
  // Expresiones regulares para identificar los formatos
  const dniRegex = /^\d{8}[A-Za-z]$/; // DNI: 8 dígitos y una letra
  const nieRegex = /^[XYZ]\d{7}[A-Za-z]$/; // NIE: X/Y/Z seguido de 7 dígitos y una letra
  const cifRegex = /^[ABCDEFGHJNPQRSUVW]\d{7}[0-9A-J]$/; // CIF: Letra inicial válida, 7 dígitos y un dígito de control (número o letra)

  // Función para calcular la letra de control del DNI/NIE
  const calcularLetraDNI = (numero) => {
    const letras = "TRWAGMYFPDXBNJZSQVHLCKE";
    return letras[numero % 23];
  };

  // Función para calcular el dígito de control del CIF
  const calcularDigitoControlCIF = (cif) => {
    const letrasControl = "JABCDEFGHI"; // Letras de control para CIF
    const digitos = cif.substring(1, 8); // Siete dígitos del CIF
    const primerCaracter = cif.charAt(0).toUpperCase();
    const ultimoCaracter = cif.charAt(8).toUpperCase();

    let sumaPar = 0;
    let sumaImpar = 0;

    // Calcular la suma de pares e impares
    for (let i = 0; i < digitos.length; i++) {
      const num = parseInt(digitos.charAt(i), 10);
      if (i % 2 === 0) { // Posiciones impares (0 indexado)
        let doble = num * 2;
        if (doble > 9) doble = Math.floor(doble / 10) + (doble % 10); // Suma de dígitos si es mayor a 9
        sumaImpar += doble;
      } else { // Posiciones pares
        sumaPar += num;
      }
    }

    const sumaTotal = sumaPar + sumaImpar;
    const digitoControlCalculado = (10 - (sumaTotal % 10)) % 10;

    // Validación del dígito de control basado en la letra inicial del CIF
    if ("PQRSNW".includes(primerCaracter)) {
      // Si el primer carácter está en 'PQRSNW', el dígito de control debe ser una letra
      return letrasControl[digitoControlCalculado] === ultimoCaracter;
    } else if ("ABEH".includes(primerCaracter)) {
      // Si el primer carácter está en 'ABEH', el dígito de control debe ser un número
      return digitoControlCalculado.toString() === ultimoCaracter;
    } else {
      // Para otros casos, puede ser letra o número
      return digitoControlCalculado.toString() === ultimoCaracter || letrasControl[digitoControlCalculado] === ultimoCaracter;
    }
  };

  // Verificación del tipo de documento y validación de la letra/dígito de control
  if (dniRegex.test(documento)) {
    // Validación de DNI
    const numero = parseInt(documento.slice(0, 8), 10);
    const letra = documento.charAt(8).toUpperCase();
    if (calcularLetraDNI(numero) === letra) {
      return "DNI";
    } else {
      return false;
    }
  } else if (nieRegex.test(documento)) {
    // Validación de NIE
    let numero = documento.slice(1, 8);
    switch (documento.charAt(0)) {
      case 'X': numero = '0' + numero; break;
      case 'Y': numero = '1' + numero; break;
      case 'Z': numero = '2' + numero; break;
    }
    numero = parseInt(numero, 10);
    const letra = documento.charAt(8).toUpperCase();
    if (calcularLetraDNI(numero) === letra) {
      return "NIE";
    } else {
      return false;
    }
  } else if (cifRegex.test(documento)) {
    // Validación completa de CIF
    if (calcularDigitoControlCIF(documento)) {
      return "CIF";
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export const getElementFromArrayById = (array, id) => {
  var element = null;
  for (var n in array) {
    if (array[n].id == id)
      element = array[n];
  }
  return element;
}

const removeNullOrEmpty = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== null && value !== '' && typeof (value) != 'undefined' && value != '-1') {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export const getCandidatoFormData = (user) => {
  var data = {
    Nombre: user.Nombre,
    Apellidos: user.Apellidos,
    Telefono: user.Telefono,

    users: { id: user.id },
  };

  data.Telefono = document.getElementById('formTelefono').value;
  data.uploadAvatar = document.getElementById('formFoto') != null && document.getElementById('formFoto').files != null && document.getElementById('formFoto').files.length > 0;
  data.avatar_url = document.getElementById('formFotoUrl').value;
  data.SobreMi = document.getElementById('formSobreTi').value;

  if (document.getElementById('formFechaNacimiento').value != '')
    data.FechaNacimiento = document.getElementById('formFechaNacimiento').value;
  if (document.getElementById('formGenero').value != '')
    data.Genero = document.getElementById('formGenero').value;

  data.ResidenteEspana = document.getElementById('formResidenteEspana').checked ? document.getElementById('formResidenteEspana').value : 0;
  data.CodigoPostal = document.getElementById('formCodigoPostal').value;
  data.Poblacion = document.getElementById('formPoblacion').value;
  data.Provincia = document.getElementById('formProvincia').value;
  data.Lat = document.getElementById('formLatitud').value;
  data.Lon = document.getElementById('formLongitud').value;

  if (document.getElementById('formExperiencia') != null && document.getElementById('formExperiencia').value != '') {
    var experiencia = JSON.parse(document.getElementById('formExperiencia').value);
    data.Experiencia = experiencia;

  }

  //data.TrabajarDe = document.getElementById('formTrabajarDe').value;
  data.sectors = { id: document.getElementById('formSectores').value };

  if (document.getElementById('formEstudios') != null && document.getElementById('formEstudios').value != '') {
    var estudios = JSON.parse(document.getElementById('formEstudios').value);
    data.Estudios = estudios;
  }
  if (document.getElementById('formIdiomas') != null && document.getElementById('formIdiomas').value != '') {
    var idiomas = JSON.parse(document.getElementById('formIdiomas').value);
    data.Idiomas = idiomas;
  }

  if (document.getElementById('formHabilidades') != null && document.getElementById('formHabilidades').value != '') {
    var habilidades = JSON.parse(document.getElementById('formHabilidades').value);
    data.Habilidades = habilidades;
  }
  if (document.getElementById('formTrabajos') != null && document.getElementById('formTrabajos').value != '') {
    var trabajos = JSON.parse(document.getElementById('formTrabajos').value);
    data.trabajos = trabajos.filter(item => item.id !== -1);
  }
  if (document.getElementById('formZonas') != null && document.getElementById('formZonas').value != '' && document.getElementById('formZonas').value != '[[]]') {
    var zonas = JSON.parse(document.getElementById('formZonas').value);
    data.zonas = zonas;
  }
  if (document.getElementById('formJornadaLaboral') != null && document.getElementById('formJornadaLaboral').value != '') {
    var jornadas = JSON.parse(document.getElementById('formJornadaLaboral').value);
    data.jornadas = jornadas;
  }
  if (document.getElementById('formTiposContrato') != null && document.getElementById('formTiposContrato').value != '') {
    var tiposContrato = JSON.parse(document.getElementById('formTiposContrato').value);
    data.tipo_contratos = tiposContrato;
  }

  if (document.getElementById('formPermisosConducir') != null && document.getElementById('formPermisosConducir').value != '') {
    var permisosConducir = JSON.parse(document.getElementById('formPermisosConducir').value);
    data.permiso_conducirs = permisosConducir;
  }

  //data.CarnetConducir = document.getElementById('formCarnetConducir').checked ? document.getElementById('formCarnetConducir').value : 0;
  data.CochePropio = document.getElementById('formCochePropio').checked ? document.getElementById('formCochePropio').value : 0;
  data.Autonomo = document.getElementById('formAutonomo').checked ? document.getElementById('formAutonomo').value : 0;

  data = removeNullOrEmpty(data);

  if (document.getElementById('formTrabajosOtros') != null) {
    var trabajosOtros = document.getElementById('formTrabajosOtros').value;
    data.trabajosOtros = trabajosOtros;
  }

  return data;
}

export const updateCandidato = async (data, validate) => {
  var result = { status: 0, error: '' };
  var candidatoId = getMiCandidatoId();
  var responseData = null;
  if (validate && !validateCandidatoFormData(data)) {
    result.status = 1;
    result.error = Constants.ERROR_MSG_PARENT + 'Todos los campos marcados con * son obligatorios.';
  } else {
    if (candidatoId != null) {
      try {
        let response = await fetch(`${Constants.API}/candidatos/${candidatoId}`, {
          method: 'PUT',
          headers: {
            Authorization: `${Constants.BEARER} ${getToken()}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "data": data }),
        });
        responseData = await response.json();
        if (responseData != null && responseData.error != null) {
          result.status = 1;
          result.error = getErrorMsg(responseData);
        } else {
          response = await fetch(`${Constants.API}/users/${getMiUserId()}`, {
            method: 'PUT',
            headers: {
              Authorization: `${Constants.BEARER} ${getToken()}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({"Telefono":data.Telefono}),
          });
        }
      } catch (error) {
        //console.error(error);
        result.status = 1;
        result.error = error;
      }
    } else {
      try {
        const response = await fetch(`${Constants.API}/candidatos/`, {
          method: 'POST',
          headers: {
            Authorization: `${Constants.BEARER} ${getToken()}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "data": data }),
        });
        responseData = await response.json();
        if (responseData != null && responseData.error != null) {
          result.status = 1;
          result.error = getErrorMsg(responseData);
        } else {
          setMiCandidatoId(responseData.data.id);
          setTipo(Constants.CANDIDATO);
        }
      } catch (error) {
        //console.error(error);
        result.status = 1;
        result.error = error;
      }
    }
  }

  try {
    if (result.status == 0 && !isNaN(getMiCandidatoId())) {
      await updateExperienciaAcumulada();
    }
  } catch (error) { }

  return result;
}

const getErrorMsg = (data) => {
  var errorMsg = Constants.ERROR_MSG_PARENT + (data != null && data.error != null ? data.error.name + '. ' + data.error.message : 'Undefined');
  return errorMsg
}

const isNothing = (obj) => {
  var isNothing = false;
  if (typeof (obj) == 'undefined' || obj == null || (isNaN(obj) && obj == '')) {
    isNothing = true;
  }

  return isNothing;
}

export const validateCandidatoFormData = (data) => {
  var valid = true;
  //console.log(data.trabajos)
  //console.log(JSON.stringify(data))

  if (isNothing(data.FechaNacimiento)) {
    //console.log('FechaNacimiento')
    valid = false;
  }
  if (isNothing(data.ResidenteEspana)) {
    //console.log('ResidenteEspana')
    valid = false;
  }
  if (isNothing(data.CodigoPostal)) {
    //console.log('CodigoPostal')
    valid = false;
  }
  if (isNothing(data.Provincia)) {
    //console.log('Provincia')
    valid = false;
  }
  if (isNothing(data.Poblacion)) {
    //console.log('Poblacion')
    valid = false;
  }
  if (isNothing(data.trabajos) || data.trabajos.length == 0) {
    valid = false;
  }
  /*if (isNothing(data.TrabajarDe)) {
    //console.log('TrabajarDe')
    valid = false;
  }*/
  /*if (isNothing(data.CarnetConducir)) {
    //console.log('CarnetConducir')
    valid = false;
  }*/
  /*if (isNothing(data.Autonomo)) {
    //console.log('Autonomo')
    valid = false;
  }*/
  return valid;
}

export const validateEmpresaFormData = (data) => {
  var valid = true;
  return valid;
}

export const validateSedeFormData = (data) => {
  var valid = true;
  return valid;
}

export const getEstrellas = () => {
  return [
    { id: 0, text: 'Sin estrellas', value: 'N0' },
    { id: 1, text: '1 estrella', value: 'N1' },
    { id: 2, text: '2 estrellas', value: 'N2' },
    { id: 3, text: '3 estrellas', value: 'N3' },
    { id: 4, text: '4 estrellas', value: 'N4' },
    { id: 5, text: '5 estrellas', value: 'N5' },
  ];
}

export const updateEmpresa = async (data, validate) => {
  var result = { status: 0, error: '' };
  var empresaId = getMiEmpresaId();
  var responseData = null;
  if (validate && !validateEmpresaFormData(data)) {
    result.status = 1;
    result.error = Constants.ERROR_MSG_PARENT + 'Todos los campos marcados con * son obligatorios.';
  } else {
    if (empresaId != null) {
      try {
        const response = await fetch(`${Constants.API}/empresas/${empresaId}`, {
          method: 'PUT',
          headers: {
            Authorization: `${Constants.BEARER} ${getToken()}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "data": data }),
        });
        responseData = await response.json();
        if (responseData != null && responseData.error != null) {
          result.status = 1;
          result.error = getErrorMsg(responseData);
        }
      } catch (error) {
        //console.error(error);
        result.status = 1;
        result.error = error;
      }
    } else {
      try {
        let token = getToken();
        let response = null;
        if (token != null) {
          response = await fetch(`${Constants.API}/empresas/`, {
            method: 'POST',
            headers: {
              Authorization: `${Constants.BEARER} ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "data": data }),
          });
        } else {
          response = await fetch(`${Constants.API}/empresas/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "data": data }),
          });

        }
        responseData = await response.json();
        if (responseData != null && responseData.error != null) {
          result.status = 1;
          result.error = getErrorMsg(responseData);
        } else {
          setMiEmpresaId(responseData.data.id);
          setTipo(Constants.EMPRESA);
        }
      } catch (error) {
        //console.error(error);
        result.status = 1;
        result.error = error;
      }
    }
  }
  return result;
}

export const updateSede = async (data, validate, sedeId) => {
  var result = { status: 0, error: '' };
  var responseData = null;
  if (validate && !validateSedeFormData(data)) {
    result.status = 1;
    result.error = Constants.ERROR_MSG_PARENT + 'Todos los campos marcados con * son obligatorios.';
  } else {
    if (sedeId != null) {
      try {
        const response = await fetch(`${Constants.API}/sedes/${sedeId}`, {
          method: 'PUT',
          headers: {
            Authorization: `${Constants.BEARER} ${getToken()}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "data": data }),
        });
        responseData = await response.json();
        if (responseData != null && responseData.error != null) {
          result.status = 1;
          result.error = getErrorMsg(responseData);
        }
      } catch (error) {
        //console.error(error);
        result.status = 1;
        result.error = error;
      }
    } else {
      try {
        const response = await fetch(`${Constants.API}/sedes/`, {
          method: 'POST',
          headers: {
            Authorization: `${Constants.BEARER} ${getToken()}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "data": data }),
        });
        responseData = await response.json();
        if (responseData != null && responseData.error != null) {
          result.status = 1;
          result.error = getErrorMsg(responseData);
        } else {
          var misSedes = getMisSedesId();
          if (misSedes == null) {
            misSedes = [responseData.data.id];
          } else if (misSedes.length > -1) {
            misSedes.push(responseData.data.id);
          }
          setMisSedesId(misSedes);
          setTipo(Constants.EMPRESA);
          var auxSedes = [];
          for (var n in misSedes) {
            auxSedes.push({ id: misSedes[n] });
          }
          result = await updateEmpresa({ sedes: auxSedes }, false);
          if (responseData != null && responseData.error != null) {
            result.status = 1;
            result.error = getErrorMsg(responseData);
          }
        }
      } catch (error) {
        //console.error(error);
        result.status = 1;
        result.error = error;
      }
    }
  }
  return result;
}

export const getEmpresaFormData = (user) => {
  var data = {
    user: { id: user.id },
  };

  if (document.getElementById('formNombreEmpresa') != null) {
    data.Nombre = document.getElementById('formNombreEmpresa').value;
  }
  if (document.getElementById('formSectores') != null) {
    data.sector = { id: document.getElementById('formSectores').value };
  }
  if (document.getElementById('formGestoria') != null) {
    data.gestoria = document.getElementById('formGestoria').value;
  }

  return removeNullOrEmpty(data);
}

export const getSedeFormData = () => {
  var data = {};

  data.Nombre = document.getElementById('formNombreSede').value;
  data.uploadImagenes = document.getElementById('formFoto') != null && document.getElementById('formFoto').files != null && document.getElementById('formFoto').files.length > 0;
  data.Imagenes = [];

  data.CodigoPostal = document.getElementById('formCodigoPostal').value;
  data.Poblacion = document.getElementById('formPoblacion').value;
  data.Provincia = document.getElementById('formProvincia').value;
  data.Lat = document.getElementById('formLatitud').value;
  data.Lon = document.getElementById('formLongitud').value;

  data.Sobre = document.getElementById('formSobre') != null ? document.getElementById('formSobre').value : '';
  data.Direccion = document.getElementById('formDireccion') != null ? document.getElementById('formDireccion').value : '';

  if (document.getElementById('formTrabajos') != null && document.getElementById('formTrabajos').value != '') {
    var trabajos = JSON.parse(document.getElementById('formTrabajos').value);
    data.trabajos = trabajos.filter(item => item.id !== -1);
  }
  if (document.getElementById('formJornadaLaboral') != null && document.getElementById('formJornadaLaboral').value != '') {
    var jornadas = JSON.parse(document.getElementById('formJornadaLaboral').value);
    data.jornadas = jornadas;
  }
  if (document.getElementById('formEstrellas') != null && document.getElementById('formEstrellas').value != '') {
    var estrellas = (document.getElementById('formEstrellas').value);
    data.Estrellas = estrellas;
  }

  data = removeNullOrEmpty(data);

  if (document.getElementById('formTrabajosOtros') != null) {
    var trabajosOtros = document.getElementById('formTrabajosOtros').value;
    data.trabajosOtros = trabajosOtros;
  }

  return data;
}

export const getContratosEmpresa = async () => {
  var misSedes = getMisSedesId();
  var contratos = [];
  var aux = null;
  for (var n in misSedes) {
    aux = await getContratos(misSedes[n], Constants.EMPRESA);
    aux = await autoFinalizarContratos(aux);
    if (aux != null && aux.length > 0) {
      contratos.push(aux);
    }
  }

  return contratos.flat(1);
}
export const getContratosEmpresaFinalizados = async () => {
  var misSedes = getMisSedesId();
  var contratos = [];
  var aux = null;
  for (var n in misSedes) {
    aux = await getContratos(misSedes[n], Constants.EMPRESA, false);
    if (aux != null && aux.length > 0) {
      contratos.push(aux);
    }
  }

  return contratos.flat(1);
}

export const getAllContratosCandidato = async (id) => {
  var contratos = [];
  var aux = await getContratos(id != null ? id : getMiCandidatoId(), Constants.CANDIDATO, true, true);
  if (aux != null && aux.length > 0) {
    contratos.push(aux);
  }
  return contratos.flat(1);
}
export const getContratosCandidato = async (id) => {
  var contratos = [];
  var aux = await getContratos(id != null ? id : getMiCandidatoId(), Constants.CANDIDATO);
  if (aux != null && aux.length > 0) {
    contratos.push(aux);
  }
  return contratos.flat(1);
}
export const getContratosCandidatoFinalizados = async (id) => {
  var contratos = [];
  var aux = await getContratos(id != null ? id : getMiCandidatoId(), Constants.CANDIDATO, false);
  if (aux != null && aux.length > 0) {
    contratos.push(aux);
  }
  return contratos.flat(1);
}
export const getContratosByUser = async () => {
  let result = [];
  let ids = [];
  let tipo = getTipo();
  if (tipo == Constants.CANDIDATO)
    ids.push(getMiCandidatoId());
  else if (tipo == Constants.EMPRESA)
    ids = getMisSedesId();

  for (let n in ids) {
    result.push(await getContratos(ids[n], tipo, true, true));
  }

  //console.log(result);
  return (result.flat(1));
}
export const getContratos = async (id, tipo, active = true, all = false) => {
  var result = null;
  var field = null;
  if (tipo == Constants.CANDIDATO)
    field = 'candidato';
  else if (tipo == Constants.EMPRESA)
    field = 'sede';
  else return result;
  var url = `${Constants.API}/contratos/?populate=*&filters[${field}][id]=${id}`;
  if (!all) {
    url += `&filters[Finalizado]=${!active}`;
  }
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: { Authorization: `${Constants.BEARER} ${getToken()}` },
    });
    const responseData = await response.json();
    result = responseData.data;
  } catch (error) {
    console.error(error);
  } finally {
    return result;
  }
};
export const autoFinalizarContratos = async (data) => {
  let result = [];
  let currentDate = new Date();
  for (let n in data) {
    if (data[n].attributes.Fecha_fin != null) {
      let finDate = new Date(data[n].attributes.Fecha_fin);
      if (currentDate > finDate) {
        try {
          const response = await fetch(`${Constants.API}/contratos/${data[n].id}`, {
            method: 'PUT',
            headers: {
              Authorization: `${Constants.BEARER} ${getToken()}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "data": { Finalizado: true } }),
          });

          let responseData = await response.json();
          await bloqCandidatoContrato(data.candidato.id, false); // TODO: check
        } catch (error) {
          console.error(error);
        }
      } else {
        result.push(data[n]);
      }
    } else {
      result.push(data[n]);
    }
  }
  return result;
}
export const getContrato = async (id) => {
  var result = null;
  try {
    const response = await fetch(`${Constants.API}/contratos/${id}?populate=*`, {
      headers: { Authorization: `${Constants.BEARER} ${getToken()}` },
    });
    const responseData = await response.json();
    result = responseData.data;
  } catch (error) {
    console.error(error);
    console.error("Error recuperando IDs de DB");
  } finally {
    return result;
  }
};

export const getSedeImagen = async (id) => {
  var imagen = null;
  var sede = await getSede(id);
  imagen = sede.attributes.Imagenes;
  return imagen;
}

export const getValoracionCandidato = async (id) => {
  let contratos = await getAllContratosCandidato(id);
  let valoracion = 0;
  let valoraciones = 0;
  if (contratos != null && contratos.length > 0) {
    for (let n in contratos) {
      if (contratos[n].attributes.Valoracion != null) {
        valoracion += parseInt(contratos[n].attributes.Valoracion.split('v')[1]);
        valoraciones++;
      }
    }
    valoracion = parseInt(valoracion / valoraciones);
  }
  let result = { value: valoracion, comments: valoraciones };
  return result;
}

export const getContratosLeft = async () => {
  var result = 0;
  try {
    const response = await fetch(`${Constants.API}/empresas/${getMiEmpresaId()}?populate[0]=Contratos`, {
      headers: { Authorization: `${Constants.BEARER} ${getToken()}` },
    });
    const responseData = await response.json();
    if (responseData.data.attributes.Contratos != null) {
      result = responseData.data.attributes.Contratos;
    }

    return result;
  } catch (error) {
    console.error(error);
  } finally {
    return result;
  }
}

export const updateContratosLeft = async () => {
  try {
    var contratosLeft = await getContratosLeft();
    const response = await fetch(`${Constants.API}/empresas/${getMiEmpresaId()}`, {
      method: 'PUT',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "data": { Contratos: contratosLeft - 1 } }),
    });

    const responseData = await response.json();
    console.log(responseData);
  } catch (error) {
    console.error(error);
  }
}

export const pluck = (array, key) => {
  return array.map(function (item) { return item[key]; });
}

export const fieldsSummarize = (label, defaultValue, fields, key, max = 3) => {
  if (fields != null && label != null && document.getElementById(label) != null) {
    var aux = pluck(fields, key);
    if (aux.length == 0) {
      aux = [defaultValue];
      document.getElementById(label).innerHTML = defaultValue;
      /*} else if (aux.length > max) {
        aux = aux.slice(0,max);
        aux.push('...');*/
    }
    document.getElementById(label).innerHTML = aux.join(', ');
  }

}

export const postTrabajo = async (nombre, sectorId) => {
  var responseData = null;
  var result = { status: 0 };
  try {
    const response = await fetch(`${Constants.API}/trabajos/`, {
      method: 'POST',
      headers: {
        //Authorization: `${Constants.BEARER} ${getToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "data": { Nombre: nombre, sector: { id: sectorId } } }),
    });
    responseData = await response.json();
    console.log(responseData);
    if (responseData != null && responseData.error != null) {
      result.status = 1;
      result.error = getErrorMsg(responseData);
    }
  } catch (error) {
    //console.error(error);
    result.status = 1;
    result.error = error;
  }
  return result;
}

export const createContrato = async (data) => {
  var responseData = null;
  var result = { status: 0 };
  try {
    const response = await fetch(`${Constants.API}/contratos/`, {
      method: 'POST',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "data": data }),
    });
    responseData = await response.json();
    await bloqCandidatoContrato(data.candidato.id, true);
    if (responseData != null && responseData.error != null) {
      result.status = 1;
      result.error = getErrorMsg(responseData);
    } else {
      result.id = responseData.data.id;
      await updateContratosLeft();
    }
  } catch (error) {
    //console.error(error);
    result.status = 1;
    result.error = error;
  }
  return result;
}


export const getMisSedes = async () => {
  var misSedes = []
  try {
    var auxSedes = await getMisSedesId();
    var auxSede = null;
    for (var n in auxSedes) {
      auxSede = await getSede(auxSedes[n]);
      misSedes.push({ id: auxSede.id, value: auxSede.id, text: auxSede.attributes.Nombre })
    }
  } catch (e) { }

  return misSedes;
}
export const getMiPrimeraSede = async () => {
  var misSedes = []
  try {
    var auxSedes = await getMisSedesId();
    var auxSede = null;
    if (auxSedes.length > 0) {
      auxSede = await getSede(auxSedes[0]);
      misSedes.push({ id: auxSede.id, value: auxSede.id, text: auxSede.attributes.Nombre })
    }
  } catch (e) { }

  return misSedes;
}

export const getMiEmpresa = async () => {
  return getEmpresa(getMiEmpresaId());
  /*var result = null;
  try {
    const response = await fetch(`${Constants.API}/empresas/${getMiEmpresaId()}`, {
      method: 'GET',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
      },
    });

    const responseData = await response.json();
    console.log(responseData)
    result = responseData.data.attributes;
  } catch (error) {
    console.error(error);
  }
  return result;*/
}

export const getMiGestoria = async () => {
  var result = null;
  try {
    const response = await fetch(`${Constants.API}/empresas/${getMiEmpresaId()}`, {
      method: 'GET',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
      },
    });

    const responseData = await response.json();
    result = responseData.data.attributes.gestoria;
  } catch (error) {
    console.error(error);
  }
  return result;
}

export const updateContratoValoracion = async (id, value, comment = null) => {
  let result = { status: 0 };
  let data = {};
  if (value != null) {
    data.Valoracion = value;
  }
  if (comment != null) {
    data.ValoracionText = comment;
  }
  if (data != {}) {
    try {
      const response = await fetch(`${Constants.API}/contratos/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `${Constants.BEARER} ${getToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "data": data }),
      });

      let responseData = await response.json();
    } catch (error) {
      //console.error(error);
      result.status = 1;
      result.error = error;
    }
  }
  return result;
}

export const deleteUser = async () => {
  let id = getMiUserId();
  let result = { status: 0 };
  let response = null;
  let responseData = null;
  let contratos = await getContratosByUser();
  let tipo = getTipo();

  // Si es un candidato, lo borramos de la tabla Zonas
  if (result.status == 0 && tipo == Constants.CANDIDATO && getMiCandidatoId() != null) {
    try {
      // Recuperamos todas las zonas del candidato y las borramos (si tiene)
      response = await deleteZonasCandidato();

    } catch (error) {
      //console.error(error);
      result.status = 1;
      result.error = error;
    }
  }

  // Si el usuario tiene contratos hechos, sólo desactivamos su perfil de candidato / empresa / sedes
  if (contratos != null && contratos.length > 0) {
    if (getTipo() == Constants.CANDIDATO) {
      try {
        response = await fetch(`${Constants.API}/candidatos/${getMiCandidatoId()}`, {
          method: 'PUT',
          headers: {
            Authorization: `${Constants.BEARER} ${getToken()}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "data": { 'Inactivo': 1, users: [] } }),
        });
      } catch (error) {
        //console.error(error);
        result.status = 1;
        result.error = error;
      }
      responseData = await response.json();
    } else if (getTipo() == Constants.EMPRESA) {
      try {
        response = await fetch(`${Constants.API}/empresas/${getMiEmpresaId()}`, {
          method: 'PUT',
          headers: {
            Authorization: `${Constants.BEARER} ${getToken()}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "data": { 'Inactivo': 1, user: [] } }),
        });
      } catch (error) {
        //console.error(error);
        result.status = 1;
        result.error = error;
      }
      responseData = await response.json();

      let sedes = getMisSedesId();
      for (let n in sedes) {
        try {
          response = await fetch(`${Constants.API}/sedes/${sedes[n]}`, {
            method: 'PUT',
            headers: {
              Authorization: `${Constants.BEARER} ${getToken()}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "data": { 'Inactivo': 1 } }),
          });
        } catch (error) {
          //console.error(error);
          result.status = 1;
          result.error = error;
        }
        responseData = await response.json();
      }

    }
  }
  // Si no tiene contratos, borramos su perfil de candidato / empresa / sedes
  else {
    if (getTipo() == Constants.CANDIDATO) {
      let candidatoId = getMiCandidatoId();
      if (candidatoId != null) {
        try {
          response = await fetch(`${Constants.API}/candidatos/${getMiCandidatoId()}`, {
            method: 'DELETE',
            headers: {
              Authorization: `${Constants.BEARER} ${getToken()}`,
            },
          });
        } catch (error) {
          //console.error(error);
          result.status = 1;
          result.error = error;
        }
        responseData = await response.json();
      }

      // Borramos la info fiscal del candidato (si la ha introducido)
      let infoFiscal = await getInfoFiscalCandidato();
      if (infoFiscal != null) {
        try {
          response = await fetch(`${Constants.API}/info-fiscals/${infoFiscal.id}`, {
            method: 'DELETE',
            headers: {
              Authorization: `${Constants.BEARER} ${getToken()}`,
            },
          });
        } catch (error) {
          //console.error(error);
          result.status = 1;
          result.error = error;
        }
        responseData = await response.json();
      }
    } else if (getTipo() == Constants.EMPRESA) {
      try {
        response = await fetch(`${Constants.API}/empresas/${getMiEmpresaId()}`, {
          method: 'DELETE',
          headers: {
            Authorization: `${Constants.BEARER} ${getToken()}`,
          },
        });
      } catch (error) {
        //console.error(error);
        result.status = 1;
        result.error = error;
      }
      responseData = await response.json();

      let sedes = getMisSedesId();
      for (let n in sedes) {
        try {
          response = await fetch(`${Constants.API}/sedes/${sedes[n]}`, {
            method: 'DELETE',
            headers: {
              Authorization: `${Constants.BEARER} ${getToken()}`,
            },
          });
        } catch (error) {
          //console.error(error);
          result.status = 1;
          result.error = error;
        }
        responseData = await response.json();
      }
    }
  }

  // Si todo OK, borramos el usuario
  if (result.status == 0) {
    try {
      response = await fetch(`${Constants.API}/users/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `${Constants.BEARER} ${getToken()}`,
        },
      });

      responseData = await response.json();

    } catch (error) {
      //console.error(error);
      result.status = 1;
      result.error = error;
    }
  }

  return result;

}

export const deleteSede = async (sedeId) => {
  var result = { status: 0 };
  let contratos = await getContratosByUser();
  let responseData = null;
  let response = null;

  if (contratos != null && contratos.length > 0) {
    try {
      response = await fetch(`${Constants.API}/sedes/${sedeId}`, {
        method: 'PUT',
        headers: {
          Authorization: `${Constants.BEARER} ${getToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "data": { 'Inactivo': 1 } }),
      });
    } catch (error) {
      //console.error(error);
      result.status = 1;
      result.error = error;
    }
    responseData = await response.json();
  } else {
    try {
      response = await fetch(`${Constants.API}/sedes/${sedeId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `${Constants.BEARER} ${getToken()}`,
        },
      });
    } catch (error) {
      //console.error(error);
      result.status = 1;
      result.error = error;
    }
    responseData = await response.json();
  }
  return result;
}

export const getSedesByEmpresa = async (id) => {
  let result = { status: 0 };
  let data = [];
  try {
    const response = await fetch(`${Constants.API}/empresas/${id}?populate=sedes`, {
      method: 'GET',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
      },
    });

    let responseData = await response.json();
    if (responseData.data != null) {
      for (var n in responseData.data.attributes.sedes.data) {
        data.push(responseData.data.attributes.sedes.data[n].id);
      }
    }

  } catch (error) {
    //console.error(error);
    result.status = 1;
    result.error = error;
  }
  result.data = data;
  return result;
}

export const getFechasStr = (Fecha_inicio, Fecha_fin, posicionActual, labelPosicionActual) => {
  if (Fecha_inicio != null) {
    if (posicionActual) {
      return `${Fecha_inicio} - ${labelPosicionActual}`;
    } else if (Fecha_fin != null) {
      return `${Fecha_inicio} - ${Fecha_fin}`;
    } else {
      return `${Fecha_inicio}`;
    }
  } else {
    if (posicionActual) {
      return `${labelPosicionActual}`;
    }
  }
}

export const finalizarContrato = async (contrato, fechaFinContrato) => {
  let result = { status: 0 };

  if (contrato.attributes.Fecha_inicio < fechaFinContrato) {
    try {
      const response = await fetch(`${Constants.API}/contratos/${contrato.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `${Constants.BEARER} ${getToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "data": { Finalizado: true, Fecha_fin: fechaFinContrato } }),
      });

      let responseData = await response.json();
      await bloqCandidatoContrato(contrato.attributes.candidato.data.id, false);
    } catch (error) {
      //console.error(error);
      result.status = 1;
      result.error = error;
    }
  } else {
    result.status = 1;
    result.error = 'Error! La fecha de fin de contrato debe ser posterior a la fecha de inicio.'
  }
  return result;
}

export const setDateYYYYmmDD = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const setDateDDmmYYYY = (strDate) => {
  let date = new Date(strDate);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
  const day = String(date.getUTCDate()).padStart(2, '0');


  return `${day}/${month}/${year}`;
}

export const getCurrentDate = () => {
  return setDateYYYYmmDD(new Date());
}

export const getMiPlan = () => {
  return localStorage.getItem(Constants.MI_PLAN);
}
export const setMiPlan = (plan) => {
  if (plan) {
    localStorage.setItem(Constants.MI_PLAN, plan);
  }
};
export const removeMiPlan = () => {
  localStorage.removeItem(Constants.MI_PLAN);
};

export const getMiPlanNombre = () => {
  return localStorage.getItem(Constants.MI_PLAN_NOMBRE);
}
export const setMiPlanNombre = (plan) => {
  if (plan) {
    localStorage.setItem(Constants.MI_PLAN_NOMBRE, plan);
  }
};
export const removeMiPlanNombre = () => {
  localStorage.removeItem(Constants.MI_PLAN_NOMBRE);
};

export const isPlanPremium = () => {
  const planesNoPremium = [-1, null, 'FR', ''];
  return (planesNoPremium.indexOf(getMiPlan()) < 0);
}

export const isPlanContrata = () => {
  const planesContrata = ['C5', 'C10', 'C20'];
  return (planesContrata.indexOf(getMiPlan()) >= 0);
}

export const getFechaRenovacion = () => {
  let currentDate = new Date();
  let newDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));
  return setDateYYYYmmDD(newDate);
}

export const getTrabajarDe = (candidatoData) => {
  let trabajos = [];
  if (candidatoData != null) {
    if (candidatoData.trabajos != null && candidatoData.trabajos.data != null) {
      for (var n in candidatoData.trabajos.data) {
        trabajos.push(candidatoData.trabajos.data[n].attributes.Nombre);
      }
    }
    if (candidatoData.trabajosOtros != null && candidatoData.trabajosOtros != '') {
      trabajos.push(candidatoData.trabajosOtros);
    }
  }
  return trabajos;
}

export const getEstudios = (candidatoData) => {
  let estudios = [];
  if (candidatoData != null) {
    if (candidatoData.Estudios != null && candidatoData.Estudios.length > 0) {
      //console.log(candidatoData)
      for (var n in candidatoData.Estudios) {
        estudios.push(candidatoData.Estudios[n].Nombre_estudios);
      }
    }
  }
  return estudios;
}

export const getBuscando = (sedeData) => {
  let trabajos = [];
  if (sedeData != null) {
    if (sedeData.attributes.trabajos != null && sedeData.attributes.trabajos.data != null) {
      for (var n in sedeData.attributes.trabajos.data) {
        trabajos.push(sedeData.attributes.trabajos.data[n].attributes.Nombre);
      }
    }
    if (sedeData.attributes.trabajosOtros != null && sedeData.attributes.trabajosOtros != '') {
      trabajos.push(sedeData.attributes.trabajosOtros);
    }
  }
  return trabajos;
}

export const sendMailGestoria = async (contratoId) => {

  let dev = Constants.API.indexOf('localhost') > 0;
  const response = await fetch(`${Constants.API}/contact/send-email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ contratoId, dev }),
  });

  if (response.ok) {
    const data = await response.json();
    //console.log('Email enviado correctamente: ' + data.message);
  } else {
    const error = await response.json();
    console.error('Error al enviar el email: ' + error.error);
  }
};

export const pedirReferencias = async (candidatoId) => {
  var result = null;
  let misSedes = getMisSedesId();
  let otraEmpresa = null;
  let otraEmpresaMail = null;
  let url = `${Constants.API}/contratos/?populate=*&filters[candidato][id]=${candidatoId}&filters[Finalizado]=1&sort=Fecha_fin:desc`;
  try {
    let response = await fetch(url, {
      headers: { Authorization: `${Constants.BEARER} ${getToken()}` },
    });
    const responseData = await response.json();
    let aux = responseData.data;
    let otraEmpresa = null;
    let miEmail = await getMiEmail();
    let candidatoNombre = null;
    if (aux.length > 0) {
      for (let n in aux) {
        if (otraEmpresaMail == null && misSedes.indexOf(aux[n].attributes.sede.data.id) < 0) {
          console.log(misSedes);
          console.log(aux[n].attributes.sede)
          otraEmpresa = await getEmpresaBySede(aux[n].attributes.sede.data.id);
          console.log(otraEmpresa)
          otraEmpresaMail = otraEmpresa.attributes.user.data.attributes.email;

          candidatoNombre = aux[n].attributes.candidato.data.attributes.Nombre + " " + aux[n].attributes.candidato.data.attributes.Apellidos;
          response = await fetch(`${Constants.API}/contact/referencias`, {
            method: 'POST',
            headers: {
              Authorization: `${Constants.BEARER} ${getToken()}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ miEmail, otraEmpresaMail, candidatoNombre }),

          });

        }
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    return result;
  }
}

export const getEmpresaBySede = async (sedeId) => {
  var result = null;
  let url = `${Constants.API}/empresas/?populate=*&filters[sedes][id]=${sedeId}`;
  try {
    const response = await fetch(url, {
      headers: { Authorization: `${Constants.BEARER} ${getToken()}` },
    });
    const responseData = await response.json();
    result = responseData.data;
    if (result.length > 0) {
      result = result[0];
    }
  } catch (error) {
    console.error(error);
  } finally {
    return result;
  }
}

export const getMiEmail = async () => {
  var result = null;
  let url = `${Constants.API}/candidatos/${getMiCandidatoId()}/?populate=*`;
  if (getTipo() == Constants.EMPRESA) {
    url = `${Constants.API}/empresas/${getMiEmpresaId()}/?populate=*`;
  }
  try {
    const response = await fetch(url, {
      headers: { Authorization: `${Constants.BEARER} ${getToken()}` },
    });
    const responseData = await response.json();
    result = responseData.data;
    if (result != null) {
      if (getTipo() == Constants.EMPRESA)
        result = result.attributes.user.data.attributes.email
      else
        result = result.attributes.users.data.attributes.email
    }
  } catch (error) {
    console.error(error);
  } finally {
    return result;
  }
}

export const bloqCandidatoContrato = async (candidatoId, bloq) => {
  var result = null;
  let url = `${Constants.API}/candidatos/${candidatoId}`;
  try {
    let response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: { bloqueadoPorContrato: bloq } }),
    });
    const responseData = await response.json();
    result = responseData.data;

    // Candidato autodesbloqueado: enviamos mail a su contratador
    if (candidatoId == getMiCandidatoId()) {
      response = await fetch(`${Constants.API}/contact/desbloqueo`, {
        method: 'POST',
        headers: {
          Authorization: `${Constants.BEARER} ${getToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ candidatoId }),

      });
      //console.log(response)
    }
  } catch (error) {
    console.error(error);
  } finally {
    return result;
  }
}

export const getMisCandidatosContratados = async () => {
  var result = [];
  var aux = await getContratosEmpresa();
  if (aux != null && aux.length > 0) {
    result = aux.map(item => parseInt(item.attributes.candidato.data.id));
  }
  return result;
}

export const deleteZonasCandidato = async () => {
  let candidatoId = getMiCandidatoId();
  let result = { status: 0 };
  let response = null;
  let responseData = null;

  // Recuperamos todas las zonas del candidato y las borramos (si tiene)
  try {
    response = await fetch(`${Constants.API}/zonas/?populate=*&filters[candidato][id]=${candidatoId}`, {
      method: 'GET',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
      },
    });
    responseData = await response.json();

    if (responseData.data.length > 0) {
      for (let n in responseData.data) {
        response = await fetch(`${Constants.API}/zonas/${responseData.data[n].id}`, {
          method: 'DELETE',
          headers: {
            Authorization: `${Constants.BEARER} ${getToken()}`,
          },
        });
      }

      responseData = await response.json();
    }
  } catch (error) {
    //console.error(error);
    result.status = 1;
    result.error = error;
  }

  return result;
}

export const updateZonasCandidato = async (zonas) => {
  let candidatoId = getMiCandidatoId();
  let result = { status: 0 };
  let response = null;
  let responseData = null;

  try {
    // Recuperamos todas las zonas del candidato y las borramos (si tiene)
    response = await deleteZonasCandidato();

    // Añadimos las zonas que vienen del formulario
    if (zonas.length > 0) {
      let zonasIds = []
      for (let n in zonas) {
        response = await fetch(`${Constants.API}/zonas`, {
          method: 'POST',
          headers: {
            Authorization: `${Constants.BEARER} ${getToken()}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: {
              'Lat': zonas[n].Lat,
              'Lon': zonas[n].Lon,
              'Label': zonas[n].Label,
              'Nombre': zonas[n].Nombre,
              'BoundingBox': zonas[n].BoundingBox,
              'candidato': { id: candidatoId },
            }
          })
        });
        responseData = await response.json();
        zonasIds.push({ 'id': responseData.data.id });
      }

      // Y finalmente actualizamos la ficha del candidato con los IDs de las zonas añadidas
      response = await fetch(`${Constants.API}/candidatos/${candidatoId}`, {
        method: 'PUT',
        headers: {
          Authorization: `${Constants.BEARER} ${getToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            'zonas': zonasIds,
          }
        })
      });

    }

  } catch (error) {
    //console.error(error);
    result.status = 1;
    result.error = error;
  }

  return result;
}

export const getPublicCandidatos = async (lat, lon) => {
  var result = 0;
  try {
    var url = `${Constants.API}/zonas`
    var response = await fetch(url, {
      method: 'GET',
    });
    var responseData = await response.json();
    if (responseData.data.length > 0) {
      result = filterResultsByProximity(lat + "," + lon, 0, responseData.data)
      result = result.length;
    }
    return result;
  } catch (error) {
    console.error(error);
  } finally {
    return result;
  }
}

export const filterResultsByProximity = (center, radius, results) => {
  var aux = [];
  if (results != null) {

    if (getTipo() == Constants.CANDIDATO) {
      // Buscar por distancia punto a punto
      for (var n in results) {
        results[n].position = [results[n].attributes.Lat, results[n].attributes.Lon]
      }
      aux = results.filter((result) => {
        const distance = calculateDistance(center.split(','), result.position);
        return distance <= radius;
      });
    } else {
      // Buscar por punto incluido en zona
      let poligonoZona = null;
      let puntoDentroDeZona = null;
      for (var n in results) {
        let addResult = false;
        // Buscador de candidatos para usuarios con perfil empresa
        if (results[n].attributes.zonas != null && results[n].attributes.zonas.data != null) {
          for (var m in results[n].attributes.zonas.data) {
            poligonoZona = JSON.parse(results[n].attributes.zonas.data[m].attributes.BoundingBox)
            puntoDentroDeZona = puntoEnPoligono(center.split(','), poligonoZona);
            if (puntoDentroDeZona) {
              addResult = true;
            }
          }
        }
        // Buscador de candidatos público disponible en la Home para empresas (sin login)
        else if (results[n].attributes.BoundingBox != null) {
          poligonoZona = JSON.parse(results[n].attributes.BoundingBox)
          puntoDentroDeZona = puntoEnPoligono(center.split(','), poligonoZona);
          if (puntoDentroDeZona) {
            addResult = true;
          }
        }
        if (addResult) {
          aux.push(results[n]);
        }
      }
    }
  }

  return aux;
};

export const getMisFacturas = async () => {
  let result = { status: 0 };
  let data = [];
  let empresa = await getMiEmpresa();

  try {
    const response = await fetch(`${Constants.API}/orders/?populate=*&filters[user][id]=${getMiUserId()}`, {
      method: 'GET',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
      },
    });

    let responseData = await response.json();
    if (responseData.data != null) {
      for (var n in responseData.data) {
        responseData.data[n].attributes.empresa = { data: empresa };
        //console.log(responseData.data[n])
        data.push(responseData.data[n]);
      }
    }

  } catch (error) {
    //console.error(error);
    result.status = 1;
    result.error = error;
  }
  result.data = data;
  return result;
}

export const getCodigoPromocional = async (code) => {
  let result = { status: 0 };
  let data = [];
  let empresa = await getMiEmpresa();

  try {
    let response = await fetch(`${Constants.API}/codigos-descuentos/?populate=*&filters[empresa][id]=${getMiEmpresaId()}&filters[Codigo]=${code}`, {
      method: 'GET',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
      },
    });

    let responseData = await response.json();
    if (responseData.data != null && responseData.data.length > 0) {
      data = responseData.data;
    }

  } catch (error) {
    //console.error(error);
    result.status = 1;
    result.error = error;
  }
  result.data = data;
  return result;
}

export const setCodigoPromocionalUsed = async (codeId) => {
  let result = { status: 0 };

  try {
    let response = await fetch(`${Constants.API}/codigos-descuentos/${codeId}`, {
      method: 'PUT',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "data": { "Usado": 1 } }),
    });

    await response.json();

  } catch (error) {
    //console.error(error);
    result.status = 1;
    result.error = error;
  }
  return result;
}

export const getRedsysData = () => {
  return JSON.parse(localStorage.getItem(Constants.REDSYS_DATA));
};

export const setRedsysData = (data) => {
  if (data) {
    localStorage.setItem(Constants.REDSYS_DATA, JSON.stringify(data));
  }
};

export const removeRedsysData = () => {
  localStorage.removeItem(Constants.REDSYS_DATA);
};

export const getExperienciaAcumulada = async (candidatoId) => {
  let result = { status: 0 };
  let response = null;
  let responseData = null;
  let data = null;

  // Recuperamos todas las zonas del candidato y las borramos (si tiene)
  try {
    response = await fetch(`${Constants.API}/experiencias-acumuladas/?populate=*&filters[candidato][id]=${candidatoId}`, {
      method: 'GET',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
      },
    });
    responseData = await response.json();

    if (responseData.data.length > 0) {
      data = convertirMilisegundosAFecha(responseData.data[0].TimeInMillis);
    }
  } catch (error) {
    //console.error(error);
    result.status = 1;
    result.error = error;
  }

  result.data = data;
  return data;
  //return result;
}

export const deleteExperienciaAcumulada = async () => {
  let candidatoId = getMiCandidatoId();
  let result = { status: 0 };
  let response = null;
  let responseData = null;

  // Recuperamos todas las zonas del candidato y las borramos (si tiene)
  try {
    response = await fetch(`${Constants.API}/experiencias-acumuladas/?populate=*&filters[candidato][id]=${candidatoId}`, {
      method: 'GET',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
      },
    });
    responseData = await response.json();

    if (responseData.data.length > 0) {
      for (let n in responseData.data) {
        response = await fetch(`${Constants.API}/experiencias-acumuladas/${responseData.data[n].id}`, {
          method: 'DELETE',
          headers: {
            Authorization: `${Constants.BEARER} ${getToken()}`,
          },
        });
      }

      responseData = await response.json();
    }
  } catch (error) {
    //console.error(error);
    result.status = 1;
    result.error = error;
  }

  return result;
}

export const updateExperienciaAcumulada = async (experiencias) => {
  let candidatoId = getMiCandidatoId();
  let result = { status: 0 };
  let response = null;
  let responseData = null;

  try {
    // Recuperamos todas las experiencias acumuladas del candidato y las borramos (si tiene)
    //console.log('borrando')
    response = await deleteExperienciaAcumulada();
    //console.log(experiencias.length)

    // Añadimos las experiencias acumuladas que vienen del formulario
    if (experiencias.length > 0) {
      let millis = 0;
      let aux = 0;
      for (let n in experiencias) {
        aux = 0;
        if (experiencias[n].Posicion_actual) {
          aux = new Date() - new Date(experiencias[n].Fecha_inicio);
        } else {
          aux = new Date(experiencias[n].Fecha_fin) - new Date(experiencias[n].Fecha_inicio);
        }
        millis += aux;
      }
      if (parseInt(millis) > 0) {
        //console.log('guardando')
        response = await fetch(`${Constants.API}/experiencias-acumuladas`, {
          method: 'POST',
          headers: {
            Authorization: `${Constants.BEARER} ${getToken()}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: {
              'candidato': { id: candidatoId },
              'TimeInMillis': millis,
            }
          })
        });
        //responseData = await response.json();

      }
    }

  } catch (error) {
    //console.error(error);
    result.status = 1;
    result.error = error;
  }

  return result;
}

export const getCandidatosExperienciaAcumulada = async (candidatos) => {
  let result = candidatos;
  for (let n in candidatos) {
    result[n].attributes.experienciaAcumulada = await getExperienciaAcumulada(candidatos[n].id);
  }
  console.log(result)
  return result;
}


export const convertirMilisegundosAFecha = (milisegundos) => {
  // Constantes para las conversiones
  const milisegundosPorSegundo = 1000;
  const segundosPorMinuto = 60;
  const minutosPorHora = 60;
  const horasPorDía = 24;

  const milisegundosPorDía = milisegundosPorSegundo * segundosPorMinuto * minutosPorHora * horasPorDía;
  const díasPorAño = 365.25;  // Considerando los años bisiestos
  const díasPorMes = díasPorAño / 12;

  // Calcular el tiempo en diferentes unidades
  let años = Math.floor(milisegundos / (milisegundosPorDía * díasPorAño));
  milisegundos -= años * milisegundosPorDía * díasPorAño;

  let meses = Math.floor(milisegundos / (milisegundosPorDía * díasPorMes));
  milisegundos -= meses * milisegundosPorDía * díasPorMes;

  let días = Math.floor(milisegundos / milisegundosPorDía);

  //return `${años} años, ${meses} meses, ${días} días`;
  let mesesStr = meses > 0 ? (`${meses} mes${meses > 1 ? 'es' : ''}`) : ''
  let añosStr = años > 0 ? (`${años} año${años > 1 ? 's' : ''}`) : ''
  return `${añosStr} ${mesesStr}`.trim();
}

export const getPlanPersonalizado = async (code) => {
  let result = { status: 0 };
  let data = [];
  let empresa = await getMiEmpresa();

  try {
    let response = await fetch(`${Constants.API}/plan-personalizados/?populate=*&filters[empresa][id]=${getMiEmpresaId()}`, {
      method: 'GET',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
      },
    });

    let responseData = await response.json();
    if (responseData.data != null && responseData.data.length > 0) {
      data = responseData.data;
    }

  } catch (error) {
    //console.error(error);
    result.status = 1;
    result.error = error;
  }
  result.data = data;
  return result;
}

export const setPlanPersonalizadoUsed = async (planId) => {
  let result = { status: 0 };

  try {
    let response = await fetch(`${Constants.API}/plan-personalizados/${planId}`, {
      method: 'PUT',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "data": { "Usado": 1 } }),
    });

    await response.json();

  } catch (error) {
    //console.error(error);
    result.status = 1;
    result.error = error;
  }
  return result;
}

export const getCandidatos = async (start = 0) => {
  try {
    var limit = Constants.PAGESIZE;
    var result = [];
    var url = `${Constants.API}/candidatos?populate=*&filters[Inactivo]=0&pagination[start]=${start}&pagination[limit]=${limit}&sort=createdAt:desc`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `${Constants.BEARER} ${getToken()}`,
      },
    });
    const responseData = await response.json();
    //console.log(responseData)
    if (responseData.data.length > 0) {
      result = responseData;
    }
  } catch (error) {
    console.error(error);
  }

  return result;
}