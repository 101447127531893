import { isMobileDevice } from "./helpers";

export const DEV_SERVER = window.origin.indexOf('localhost')>0;
export const AVATAR_API = "https://ui-avatars.com/api";
//export const API = "https://contratatusector.com/str/api";
//export const STRAPI = "https://contratatusector.com";
export const API = DEV_SERVER ? "http://localhost:1337/api" : "https://contratatusector.pro/str/api";
export const STRAPI = DEV_SERVER ? "http://localhost:1337" : "https://contratatusector.pro/str";
export const AUTH_TOKEN = "authToken";
export const BEARER = "Bearer";
export const USER = "user";
export const CANDIDATO = "Candidato";
export const EMPRESA = "Empresa";
export const SEDE = "Sede";
export const IS_PLAN_PREMIUM = "IsPlanPremium";
export const MI_PLAN = "MiPlan";
export const MI_PLAN_NOMBRE = "MiPlanNombre";
export const REDSYS_DATA = "RedsysData";


export const M6R = '354366775';
export const C6R = 978;

export const ERROR_MSG_PARENT = "Ha ocurrido un error: ";

// Routes
export const PATH_TEST = "/test";
export const PATH = "/";
export const PATH_COM = "https://contratatusector.com/";
export const PATH_APP = PATH+'App/';
export const PATH_APP_LOGOUT = PATH_APP+'Logout';
export const PATH_LOGIN = PATH+'Login';
export const PATH_LOGOUT = PATH+'Logout';
export const PATH_REGISTER = PATH+'Register';
export const PATH_REGISTERED = PATH+'Registered';
export const PATH_VERIFIED = PATH+'Verified';
export const PATH_RESET_PASSWORD = PATH+'ResetPassword';
export const PATH_DO_RESET_PASSWORD = PATH+'DoResetPassword';
export const PATH_REGISTER_CANDIDATO = PATH_REGISTER+CANDIDATO;
export const PATH_REGISTER_EMPRESA = PATH_REGISTER+EMPRESA;
export const PATH_REGISTER_USER_CANDIDATO = PATH_REGISTER+'User'+CANDIDATO;
export const PATH_REGISTER_USER_EMPRESA = PATH_REGISTER+'User'+EMPRESA;
export const PATH_APP_REGISTER_CANDIDATO = PATH_APP+'Register'+CANDIDATO;
export const PATH_APP_REGISTER_SEDE = PATH_APP+'Register'+SEDE;
export const PATH_APP_PROFILE = PATH_APP+'Profile';
export const PATH_APP_PROFILE_CANDIDATO = PATH_APP_PROFILE+CANDIDATO;
export const PATH_APP_PROFILE_FISCAL_CANDIDATO = PATH_APP_PROFILE+'Fiscal'+CANDIDATO;
export const PATH_APP_PROFILE_EMPRESA = PATH_APP_PROFILE+EMPRESA;
export const PATH_APP_PROFILE_SEDE = PATH_APP_PROFILE+SEDE;
export const PATH_ROL = PATH+'Rol';
export const PATH_APP_ROL = PATH_APP+'Rol';
export const PATH_APP_TUS_EMPRESAS = PATH_APP + 'TusEmpresas';
export const PATH_APP_TUS_SEDES = PATH_APP + 'TusSedes';
export const PATH_APP_FORM_REDSYS = PATH_APP + 'Redsys';
export const PATH_APP_FORM_REDSYS_ACCEPT = PATH_APP + 'PagoAceptado';
export const PATH_APP_FORM_REDSYS_CANCEL = PATH_APP + 'PagoFallado';
export const PATH_APP_CONTRATO = PATH_APP+'Contrato';
export const PATH_APP_CONTRATO_CANDIDATO = PATH_APP_CONTRATO+CANDIDATO;
export const PATH_APP_CONTRATO_EMPRESA = PATH_APP_CONTRATO+EMPRESA;
export const PATH_APP_CONTRATAR = PATH_APP+'Contratar';
export const PATH_APP_CONTRATADO = PATH_APP+'Contratado';

export const PATH_AVISO = PATH+'Aviso';
//export const PATH_CONDICIONES_VENTA = PATH+'CondicionesVenta';
export const PATH_QUIENES_SOMOS = PATH+'QuienesSomos';
export const PATH_FAQ = PATH+'Faq';
export const PATH_FAQ_CANDIDATOS = PATH_FAQ+CANDIDATO;
export const PATH_FAQ_EMPRESAS = PATH_FAQ+EMPRESA;
export const PATH_COOKIES = PATH+'Cookies';
export const PATH_RGPD = PATH+'Rgpd';
export const PATH_TERMS_CONDITIONS = PATH+'TerminosCondiciones';
export const PATH_TERMS_CONDITIONS_CANDIDATO = PATH+'TerminosCondicionesCandidato';
export const PATH_TERMS_CONDITIONS_EMPRESA = PATH+'TerminosCondicionesEmpresa';
export const PATH_CONDICIONES_VENTA = PATH+'CondicionesVenta';
export const PATH_FORGOT_PASSWORD = PATH+'ForgotPassword';


export const PATH_APP_CONDICIONES_VENTA = PATH_APP+'CondicionesVenta';
export const PATH_APP_ULTIMOS_EMPLEADOS = PATH_APP + 'UltimosEmpleados';
export const PATH_APP_ULTIMAS_EMPRESAS = PATH_APP + 'UltimasEmpresas';
export const PATH_APP_ENTREVISTAS_A_EMPRESAS = PATH_APP + 'EntrevistasCandidato';
export const PATH_APP_CONTRATOS_A_EMPRESAS = PATH_APP + 'ContratosCandidato';
export const PATH_APP_CONTRATOS_A_EMPRESAS_FINALIZADOS = PATH_APP + 'ContratosCandidatoFinalizados';
export const PATH_APP_EMPRESAS_FAVORITOS = PATH_APP + 'FavoritosCandidato';
export const PATH_APP_EMPRESAS_BLOQUEADOS = PATH_APP + 'BloqueadoosCandidato';
export const PATH_APP_ENTREVISTAS_A_CANDIDATOS = PATH_APP + 'EntrevistasEmpresa';
export const PATH_APP_CONTRATOS_A_CANDIDATOS = PATH_APP + 'ContratosEmpresa';
export const PATH_APP_CONTRATOS_A_CANDIDATOS_FINALIZADOS = PATH_APP + 'ContratosEmpresaFinalizados';
export const PATH_APP_CANDIDATOS_FAVORITOS = PATH_APP + 'FavoritosEmpresa';
export const PATH_APP_PLANES = PATH_APP + 'Planes';
export const PATH_APP_PLAN_SINGLE = PATH_APP + 'PlanSingle';
export const PATH_APP_PLAN_FREE = PATH_APP + 'PlanFree';
export const PATH_APP_PLAN_PACK = PATH_APP + 'PlanPack';
export const PATH_APP_RGPD = PATH_APP+'Rgpd';
export const PATH_APP_TERMS_CONDITIONS = PATH_APP+'TerminosCondiciones';
export const PATH_APP_FACTURAS = PATH_APP + 'Facturas';

export const PATH_PARA_EMPRESAS = PATH+'Empresas';
export const PATH_PARA_EMPLEADOS = PATH;
export const PATH_MAPA_CANDIDATOS = PATH+'MapaCandidatos';
export const PATH_APP_SEARCH_CANDIDATO = PATH_APP + 'SearchCandidato';
export const PATH_APP_SEARCH_EMPRESA = PATH_APP + 'SearchEmpresa';

export const PERMISOS_CONDUCIR = 'permisosConducir';
export const IDIOMAS = 'idiomas';
export const NIVEL_IDIOMAS = 'nivelIdiomas';
export const JORNADAS = 'jornadas';
export const TIPO_ESTUDIOS = 'tipoEstudios';
export const TIPO_CONTRATO = 'tipoContrato';
export const TRABAJOS = 'trabajos';
export const SECTORES = 'sectores';

export const TIPO = 'tipo';
export const POSICION = 'posicion';
export const MI_CANDIDATO_ID = 'miCandidatoId';
export const MI_EMPRESA_ID = 'miEmpresaId';
export const MIS_SEDES_ID = 'misSedesId';
export const MI_USER_ID = 'miUserId';

export const ACTION_CLOSE = 'close';
export const PAGO_ANUAL = 'Pago Anual';

export const PLAN_FREE = 'FR';
export const PLAN_PREMIUM = 'PR';
export const PLAN_CONTRATA_5 = 'C5';
export const PLAN_CONTRATA_10 = 'C10';
export const PLAN_CONTRATA_20 = 'C20';
export const PLAN_PACK_5 = 'P5';
export const PLAN_PACK_10 = 'P10';
export const PLAN_PACK_20 = 'P20';
export const PLAN_PERSONALIZADO = 'PP';

export const ELIMINAR_CUENTA = 'eliminarCuenta';
export const ELIMINAR_SEDE = 'eliminarSede';
export const FINALIZAR_CONTRATO = 'finalizarContrato';

export const SIDEBAR_OVERLAY = 'SidebarOverlay';
export const SIDEBAR_CONFIG_CANDIDATO = 'SidebarConfigCandidato';
export const SIDEBAR_CONFIG_EMPRESA = 'SidebarConfigEmpresa';
export const SIDEBAR_BUSCAR_CANDIDATOS = 'SidebarBuscarCandidatos';
export const SIDEBAR_BUSCAR_EMPRESA = 'SidebarBuscarEmpresas';
export const SIDEBAR_EDIT_DATOS_CANDIDATO = 'SidebarEditDatosCandidato';
export const SIDEBAR_EDIT_DATOS_EMPRESA = 'SidebarEditDatosEmpresa';
export const SIDEBAR_EDIT_DATOS_SEDE = 'SidebarEditDatosSede';
export const SIDEBAR_EDIT_DATOS_FISCALES_CANDIDATO = 'SidebarEditDatosFiscalesCandidato';
export const SIDEBAR_EDIT_DATOS_FISCALES_EMPRESA = 'SidebarEditDatosFiscalesEmpresa';
export const SIDEBAR_SEARCH = 'SidebarSearch';
export const SIDEBAR_SEARCH_MAP = 'SidebarSearchMap';
export const SIDEBAR_SEARCH_CANDIDATO = 'SidebarSearchCandidato';
export const SIDEBAR_SEARCH_EMPRESA = 'SidebarSearchEmpresa';
export const SIDEBAR_EDIT_DATOS_CANDIDATO_IDIOMAS = 'SidebarEditDatosCandidatoIdiomas';
export const SIDEBAR_EDIT_DATOS_CANDIDATO_ESTUDIOS = 'SidebarEditDatosCandidatoEstudios';
export const SIDEBAR_EDIT_DATOS_CANDIDATO_EXPERIENCIAS = 'SidebarEditDatosCandidatoExperiencias';
export const SIDEBAR_EDIT_DATOS_CANDIDATO_HABILIDADES = 'SidebarEditDatosCandidatoHabilidades';
export const SIDEBAR_EDIT_DATOS_CANDIDATO_JORNADAS = 'SidebarEditDatosCandidatoJornadas';
export const SIDEBAR_EDIT_DATOS_CANDIDATO_CODIGO_POSTAL = 'SidebarEditDatosCandidatoCodigoPostal';
export const SIDEBAR_EDIT_DATOS_CANDIDATO_TIPOS_CONTRATO = 'SidebarEditDatosCandidatoTiposContrato';
export const SIDEBAR_EDIT_DATOS_CANDIDATO_TRABAJOS = 'SidebarEditDatosCandidatoTrabajos';
export const SIDEBAR_EDIT_DATOS_CANDIDATO_TRABAJO_BUSCADO = 'SidebarEditDatosCandidatoTrabajoBuscado';
export const SIDEBAR_EDIT_DATOS_CANDIDATO_SECTORES = 'SidebarEditDatosCandidatoSectores';
export const SIDEBAR_EDIT_DATOS_CANDIDATO_ZONAS = 'SidebarEditDatosCandidatoZonas';
export const SIDEBAR_EDIT_DATOS_CANDIDATO_PERMISOS_CONDUCIR = 'SidebarEditDatosCandidatoPermisosConducir';

export const SIDEBAR_EDIT_DATOS_SEDE_TRABAJOS = 'SidebarEditDatosSedeTrabajos';
export const SIDEBAR_EDIT_DATOS_SEDE_JORNADAS = 'SidebarEditDatosSedeJornadas';
export const SIDEBAR_EDIT_DATOS_SEDE_CODIGO_POSTAL = 'SidebarEditDatosSedeCodigoPostal';

export const SIDEBAR_SEARCH_TRABAJOS = 'SidebarSearchTrabajos';
export const SIDEBAR_SEARCH_IDIOMAS = 'SidebarSearchIdiomas';
export const SIDEBAR_SEARCH_EXPERIENCIAS = 'SidebarSearchExperiencias';
export const SIDEBAR_SEARCH_JORNADAS = 'SidebarSearchJornadas';
export const SIDEBAR_SEARCH_CODIGO_POSTAL = 'SidebarSearchCodigoPostal';
export const SIDEBAR_SEARCH_TIPO_ESTUDIOS = 'SidebarSearchTipoEstudios';
export const SIDEBAR_SEARCH_PERMISOS_CONDUCIR = 'SidebarSearchPermisosConducir';

export const LANDING_MENU = 'LandingMenu';


export const NOMINATIM_QUERY_POSTALCODE = 'https://nominatim.openstreetmap.org/search?limit=1&format=json&addressdetails=1&country=spain&postalcode=';
export const NOMINATIM_QUERY_CITY = 'https://nominatim.openstreetmap.org/search?limit=10&format=json&addressdetails=1&countrycodes=es&city=';

export const FORM_SEARCH = 'formSearch';
export const FORM_SEARCH_CANDIDATO = 'formSearchCandidato';
export const FORM_SEARCH_EMPRESA = 'formSearchEmpresa';

export const MAX_SEDES_PER_USER = 2;

export const USER_CANDIDATO_VOID = {
    "Nombre": "",
    "Apellidos": "",
    "createdAt": "",
    "updatedAt": "",
    "locale": "es",
    "SobreMi": "",
    "CodigoPostal": "",
    "Distancia": null,
    "FechaNacimiento": null,
    "Telefono": "",
    "TrabajarDe": "",
    "CochePropio": null,
    "Genero": null,
    "ResidenteEspana": true,
    "Provincia": "",
    "Poblacion": "",
    "CarnetConducir": null,
    "avatar_url": "",
    "Latitud": 0,
    "Longitud": 0,
    "Estudios": [],
    "permiso_conducirs": {
        "data": []
    },
    "jornadas": {
        "data": []
    },
    "Experiencia": [],
    "users": {
        "data": []
    },
    "Idiomas": [],
    "Habilidades": [],
    "sectors": {
        "data": []
    },
    "localizations": {
        "data": []
    }
};

export const USER_SEDE_VOID = {
    "Nombre": "",
    "CodigoPostal": "",
    "Poblacion": "",
    "Provincia": "",
    "Estrellas": "",
    "Lat": 0,
    "Lon": 0,
    "Direccion": "",
    "Sobre": "",
    "contratos": { "data": [] },
    "Imagenes": [],
    "trabajos":  { "data": [] },
}

export const USER_INFO_FISCAL_VOID = {
    "id":null,
    "DniFront":"",
    "DniBack":"",
    "NumSegSocial":"",
    "IBAN":"",
    "FamiliaNumerosa":false,
}
const whatsappMobileUrl = 'whatsapp://send?phone=';
const whatsappWebUrl = 'https://web.whatsapp.com/send?phone=';

export const WHATSAPP_URL = isMobileDevice() ? whatsappMobileUrl : whatsappWebUrl;

export const PAGESIZE = 10;